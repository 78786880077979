import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import "./account.css";
import authService from "../../services/auth.service";

import ProfileGroup from "../../components/ProfileGroup";
import styled, { keyframes } from "styled-components";
import userService from "../../services/user.service";
import { fadeIn } from "react-animations";
import villain from "../../avatars/villain.png";
import coffee from "../../avatars/coffee.png";
import cupcake from "../../avatars/cupcake.png";
import lemon from "../../avatars/lemon.png";
import penguin from "../../avatars/penguin.png";
import flower from "../../avatars/flower.png";
import sun from "../../avatars/sun.png";
import moon from "../../avatars/moon.png";
import Popup from "../../components/Popup";

const FadeFast = styled.div`
  animation: 0.5s ${keyframes`${fadeIn} `};
`;
const FadeSlower = styled.div`
  animation: 1s ${keyframes`${fadeIn}`};
`;
function Account() {
  const [show, setShow] = useState(false);
  const [avatar, setAvatar] = useState({ name: "villain", image: villain });
  const [avatarColor, setAvatarColor] = useState("#342d44");
  const [totalHours, setTotalHours] = useState(0);
  const [volunteer, setVolunteer] = useState(0);
  let user = authService.getCurrentUser();

  useEffect(() => {
    userService.getUserProfile({ id: user.id }).then(function (res) {
      setProfile(res.data);
      possibleImages.forEach((img) => {
        if (img.name === res.data.avatar) {
          setAvatar({ name: img.name, image: img.image });
        }
      });
      setAvatarColor(res.data.avatar_color);
      console.log("now calling hours");
      userService.getTotalHours({ id: user.id }).then(function (hourRes) {
        console.log(hourRes);
        let volunteer = hourRes.data.volunteer;
        let weekly = hourRes.data.weekly_hours;
        for (let key in weekly) {
          console.log(`${key}: ${weekly[key]}`);
        }
        setVolunteer(volunteer);
        setTotalHours(hourRes.data.total_hours);
      });
    });
  }, []);

  const [profile, setProfile] = useState({
    id: user.id,
    name: user.name,
    email: user.email,
    phone: "",
    address: "",
    facebook: "",
    twitter: "",
    instagram: "",
    ec_name: "",
    ec_phone: "",
  });

  let possibleImages = [
    { name: "villain", image: villain },
    { name: "coffee", image: coffee },
    { name: "flower", image: flower },
    { name: "penguin", image: penguin },
    { name: "lemon", image: lemon },
    { name: "cupcake", image: cupcake },
    { name: "sun", image: sun },
    { name: "moon", image: moon },
  ];
  const inputChange = (e) => {
    let { value, name } = e.target;
    setProfile({ ...profile, [name]: value });
  };
  const saveDetails = () => {
    let sendProfile = { ...profile };
    sendProfile.id = user.id;
    sendProfile.avatar = avatar.name;

    sendProfile.avatar_color = avatarColor;
    console.log(sendProfile);
    userService.updateUserProfile({ profile: sendProfile });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(avatarColor);
  return (
    <div className="flex">
      <Sidebar />
      <Popup
        handleClose={handleClose}
        handleShow={handleShow}
        bodyContent={possibleImages.map((entry) => {
          return (
            <img
              onClick={(e) =>
                setAvatar({ name: e.target.name, image: e.target.src })
              }
              className="choose-new-avatar"
              style={{ background: avatarColor }}
              name={entry.name}
              src={entry.image}
              alt="avatar"
            ></img>
          );
        })}
        redText={
          <div className="color-picker-container">
            <p className="color-picker-text">Choose Avatar Color</p>
            <input
              onChange={(e) => {
                setAvatarColor(e.target.value);
              }}
              value={avatarColor}
              type="color"
            />
          </div>
        }
        show={show}
        title="Choose a New Avatar"
        buttonText="Confirm"
        handleSubmit={handleClose}
      ></Popup>
      <div className="account-container">
        <header className="header">Account Details</header>
        <div className="details">
          <FadeFast>
            <div className="d-flex align-items-center  flex-column text-center">
              <h4 className="user-name-header">{user.name}</h4>
              <img
                onClick={handleShow}
                className="mt-4 avatar"
                src={avatar.image}
                style={{ background: avatarColor }}
                alt="user icon"
              ></img>
              <div className="hours-container">
                <p>
                  {totalHours < 241 ? <p>Total Hours: {totalHours}</p> : null}
                </p>
                <p>Volunteer Hours: {volunteer}</p>
              </div>
            </div>
          </FadeFast>

          <FadeSlower>
            <div className="d-flex all-input-prof">
              <div className="full-at-small gen-info">
                <header className="sub-header">General Information</header>
                <ProfileGroup
                  icon="user"
                  placeholder="name"
                  value={profile.name}
                />
                <ProfileGroup
                  icon="envelope"
                  placeholder="example@email.com"
                  value={profile.email}
                />
                <ProfileGroup
                  value={profile.phone}
                  name="phone"
                  onChange={inputChange}
                  icon="phone"
                  placeholder="(111) 111-1111"
                />
                <ProfileGroup
                  value={profile.address}
                  name="address"
                  onChange={inputChange}
                  icon="building"
                  placeholder="1234 your street"
                />
                <h1 className="ec sub-header">Emergency Contact</h1>
                <ProfileGroup
                  value={profile.ec_name}
                  name="ec_name"
                  onChange={inputChange}
                  icon="user"
                  placeholder="name"
                />
                <ProfileGroup
                  value={profile.ec_phone}
                  name="ec_phone"
                  onChange={inputChange}
                  icon="phone"
                  placeholder="(111) 111-1111"
                />
              </div>
              <div className="full-at-small social-media">
                <header className="sub-header">Social Media</header>
                <ProfileGroup
                  value={profile.facebook}
                  onChange={inputChange}
                  name="facebook"
                  icon="facebook-f"
                  placeholder="@example"
                />
                <ProfileGroup
                  value={profile.twitter}
                  onChange={inputChange}
                  name="twitter"
                  icon="twitter"
                  placeholder="@example"
                />

                <ProfileGroup
                  value={profile.instagram}
                  onChange={inputChange}
                  name="instagram"
                  icon="instagram"
                  placeholder="@example"
                />
              </div>
            </div>
            <button onClick={saveDetails} className="purp-btn">
              save
            </button>
          </FadeSlower>
        </div>
      </div>
    </div>
  );
}

export default Account;
