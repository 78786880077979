import React, { useState } from "react";

function ProjectViewInput(props) {
  const [pInput, setPInput] = useState("");
  return (
    <>
      <input
        value={pInput}
        onChange={(e) => {
          setPInput(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.searchProject(pInput);
            setPInput("");
          }
        }}
        className=" fancy-input"
        placeholder="project name"
      ></input>
      <button
        onClick={() => {
          props.searchProject(pInput);
          setPInput("");
        }}
        className="mt-2 ms-btn purp-btn"
      >
        Search
      </button>
    </>
  );
}

export default ProjectViewInput;
