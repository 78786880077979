import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import "./timesheet.css";
import FontAwesome from "react-fontawesome";
import { useNavigate } from "react-router-dom";
import timeService from "../../services/time.service";
import authService from "../../services/auth.service";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function TimesheetSelect() {
  const user = authService.getCurrentUser().id;
  const role = authService.getCurrentUser().roles;
  const navigate = useNavigate();
  const now = new Date();
  const month = 14 * 24 * 60 * 60 * 1000;
  const [sheets, setSheets] = useState([]);
  function handleNew() {
    navigate("/time-sheets-create-new");
  }
  function handleEdit(date) {
    navigate(`/time-sheets/edit/${date}`);
  }
  function handleAdmin() {
    navigate("/time-sheets-admin");
  }
  useEffect(() => {
    timeService.getTimeSheetsByUser({ uid: user }).then(function (res) {
      let toBeSorted = [...res.data];
      console.log(toBeSorted);
      toBeSorted.sort((a, b) => {
        let firstDate = new Date(a.week_ending);
        let secondDate = new Date(b.week_ending);
        return firstDate.getTime() - secondDate.getTime();
      });
      console.log(toBeSorted);
      setSheets(toBeSorted);
    });
  }, []);
  return (
    <div className="flex">
      <Sidebar />
      <div className="timesheet-overview">
        <div className="ts-header">
          <h1 className="header">Time Sheets</h1>
          {role.includes("ROLE_MODERATOR") || role.includes("ROLE_ADMIN") ? (
            <button onClick={handleAdmin} className="ml-auto purp-btn">
              admin view
            </button>
          ) : null}
        </div>
        <Fade>
          <div className="timesheet-container">
            {sheets.map((sheet, i) => {
              if (
                now.getTime() - new Date(sheet.week_ending).getTime() <=
                month
              ) {
                return (
                  <div key={i} className="timesheet-card">
                    <p>{sheet.week_ending}</p>

                    <button
                      onClick={() => handleEdit(sheet.week_ending)}
                      className="white-btn"
                    >
                      edit
                    </button>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <div className="icon-card">
              <FontAwesome
                onClick={handleNew}
                className="nav-icon timesheet-icon"
                name="plus-circle"
                size="5x"
                style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
              />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default TimesheetSelect;
