import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import ClockLoader from "react-spinners/ClockLoader";
import { css } from "@emotion/css";

function Loading() {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  return (
    <div className="loading-container">
      <ClockLoader className="loadingIcon" size={200} color="white" />
    </div>
  );
}

export default Loading;
