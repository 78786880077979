import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
function Popup(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.bodyContent}

          <br />
          <span className="red">{props.redText}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            className="modal-submit-btn"
            variant="primary"
            onClick={props.handleSubmit}
          >
            {props.buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Popup;
