import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);
function Pi(props) {
  const data = {
    labels: [
      "Proper Villains",
      "Spydersoft",
      "4Core",
      "Adiuvo",
      "Biznected",
      "Groktek",
    ],
    datasets: [
      {
        label: "Hours Per Company",
        data: props.data,
        backgroundColor: [
          "#e82020",
          "#0CB14B",
          "#F17F21",
          "#09527f",
          "#6d2570",
          "#000000",
        ],
        borderColor: [
          "#ffffff",
          "#ffffff",
          "#ffffff",
          "#ffffff",
          "#ffffff",
          "#ffffff",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="company-doughnut">
      <Doughnut datasetIdKey="id" data={data} />
    </div>
  );
}

export default Pi;
