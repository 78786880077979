import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import projectService from "../../services/project.service";
import authService from "../../services/auth.service";
import Sidebar from "../../components/Sidebar";
import ProjectViewInput from "../../components/ProjectViewInput";
import Select from "react-select";
import _ from "lodash";
import Loading from "../../components/Loading";
import userService from "../../services/user.service";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;

function View() {
  const navigate = useNavigate();
  const adminView = parseInt(localStorage.getItem("adminView")) || null;
  let userId = authService.getCurrentUser().id;
  const roles = authService.getCurrentUser().roles;
  const [projects, setProjects] = useState([]);
  const [staticProjects, setStaticProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const sortOptions = [
    { value: "alphabetical", label: "Alphabetical" },
    { value: "status", label: "Status" },
    { value: "due-date", label: "Due Date" },
  ];
  const statusOptions = [
    {
      label: "Not Started",
      value: "not started",
    },
    {
      label: "In Progress",
      value: "in progress",
    },
    {
      label: "Client Review",
      value: "client review",
    },
    { label: "Completed", value: "completed" },
    {
      label: "On Pause",
      value: "on pause",
    },
  ];
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#342d44" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  useEffect(() => {
    userId = authService.getCurrentUser().id;
    projectService.getProjects({ id: userId, adminView }).then(function (res) {
      let newList = [...res.data];
      newList = _.groupBy(newList, (proj) => proj.status);
      let temp = [];
      for (let i in newList) {
        temp.push(newList[i]);
      }
      let newListFinal = flatten([...temp]);

      setProjects(newListFinal);
      console.log("api call ");
      setStaticProjects(newListFinal);
      setLoading(!loading);
      userService.getAllUsers().then(function (res) {
        let tempArr = [];
        res.data.forEach((user) => {
          tempArr.push({ value: user, label: user });
        });
        setUsers(tempArr);
      });
    });
  }, []);

  useEffect(() => {
    if (search.length != 0) {
      let tempArr = [...staticProjects];
      tempArr = tempArr.filter((obj) => {
        return JSON.stringify(obj).toLowerCase().includes(search.toLowerCase());
      });
      setProjects(tempArr);
    } else {
      setProjects(staticProjects);
    }
  }, [search]);
  function getProjectsByName(e) {
    setUser(e.value);
  }
  useEffect(() => {
    if (user) {
      projectService
        .byUsername({ name: user, uid: userId })
        .then(function (res) {
          let newList = [...res.data];
          newList = _.groupBy(newList, (proj) => proj.status);
          let temp = [];
          for (let i in newList) {
            temp.push(newList[i]);
          }
          let newListFinal = flatten([...temp]);

          setProjects(newListFinal);
        });
    }
  }, [user]);

  function sortChange(e) {
    let by = e.value;
    let newList = [...projects];
    if (by === "status") {
      newList = _.groupBy(newList, (proj) => proj.status);
      let temp = [];
      for (let i in newList) {
        temp.push(newList[i]);
      }
      let newListFinal = flatten([...temp]);

      setProjects(newListFinal);
    } else if (by == "alphabetical") {
      newList = newList.sort((a, b) => {
        var textA = a.project_name.toUpperCase();
        var textB = b.project_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setProjects(newList);
    } else if (by === "due-date") {
      newList = setProjects(() =>
        newList.sort((a, b) => {
          var dateA = new Date(a.estimated_end_date);
          var dateB = new Date(b.estimated_end_date);
          return dateA == "Invaid Date" ? 1 : dateA > dateB ? -1 : 1;
        })
      );
    }
  }
  function handleEdit(path) {
    navigate(`/projects/edit/${path}`);
  }

  function statusUpdate(e, id) {
    let newStatus = e.value;
    projectService.updateStatus({ id, status: newStatus });
    let newList = [...projects];
    newList.forEach((proj) => {
      if (proj.id === id) {
        proj.status = newStatus;
      }
    });
    setProjects(newList);
  }
  // flatten array by noah freitas on stack overflow
  function flatten(arr) {
    return arr.reduce(function (flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
      );
    }, []);
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="content-container">
        <h1 className="header">Your Projects</h1>
        <div className="controls-container">
          <div className="search-container">
            <p className="group-header">Search</p>
            <ProjectViewInput searchProject={setSearch} />

            {roles.includes("ROLE_ADMIN") ? (
              <div>
                <p className="mt-2 text-right group-header"> Filter User</p>
                <Select
                  styles={customStyles}
                  onChange={getProjectsByName}
                  options={users}
                />
              </div>
            ) : null}
          </div>
          <div className="grouping-container">
            <p className="group-header">Group By...</p>
            <Select
              styles={customStyles}
              onChange={sortChange}
              options={sortOptions}
            />
          </div>
        </div>
        <Fade>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <Table striped bordered hover className="desktop-projects-view">
                <thead>
                  <tr>
                    <th className="view-thead">#</th>
                    <th className="view-thead">Project Name</th>
                    <th className="view-thead">Status</th>
                    <th className="view-thead">Due Date</th>
                    <th className="view-thead">Edit</th>
                    <th className="view-thead">Milestones</th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((project, i) => {
                    if (project.status == null) return null;
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{project.project_name}</td>

                        <td>
                          <Select
                            onChange={(e) => {
                              statusUpdate(e, project.id);
                            }}
                            value={{
                              label: (
                                <div className="select-flex">
                                  <span
                                    className={
                                      project.status.replace(/\s/g, "") +
                                      " circle"
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: "&#9679",
                                    }}
                                  />
                                  <span
                                    className="boldish"
                                    dangerouslySetInnerHTML={{
                                      __html: project.status,
                                    }}
                                  />
                                </div>
                              ),
                              value: project.status,
                            }}
                            options={statusOptions}
                          />
                        </td>
                        <td>{project.estimated_end_date || "no date set"}</td>
                        <td align="center">
                          <button
                            onClick={() => handleEdit(project.id)}
                            className="edit-btn"
                          >
                            edit
                          </button>
                        </td>
                        <td align="center">
                          <button
                            className="edit-btn"
                            onClick={() =>
                              navigate(`/projects/milestone/${project.id}`)
                            }
                          >
                            {project.milestone_count
                              ? `${project.milestone_complete} / ${project.milestone_count}`
                              : "view"}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
          <div className="mobile-projects-view">
            {projects.map((project, i) => {
              return (
                <div className="mobile-project m-2">
                  <h2 className="text-center sub-header white">
                    {project.project_name}
                  </h2>
                  <h3 className="mb-3 text-center white">
                    Due: {project.estimated_end_date}
                  </h3>
                  <Select
                    onChange={(e) => {
                      statusUpdate(e, project.id);
                    }}
                    value={{
                      label: (
                        <div className="select-flex">
                          <span
                            className={
                              project.status.replace(/\s/g, "") + " circle"
                            }
                            dangerouslySetInnerHTML={{
                              __html: "&#9679",
                            }}
                          />
                          <span
                            className="boldish"
                            dangerouslySetInnerHTML={{
                              __html: project.status,
                            }}
                          />
                        </div>
                      ),
                      value: project.status,
                    }}
                    options={statusOptions}
                  />
                  <div className="flex">
                    <button
                      onClick={() => handleEdit(project.id)}
                      className="mt-3 white-btn"
                    >
                      Edit Project
                    </button>
                    <button
                      className="mt-3 white-btn"
                      onClick={() =>
                        navigate(`/projects/milestone/${project.id}`)
                      }
                    >
                      Milestones
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default View;
