import axios from "axios";
import authHeader from "./auth-header";

const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "http://fomis.herokuapp.com/api/";

class TimeService {
  submitTime(data) {
    return axios.post(API_URL + "time/new", data, { headers: authHeader() });
  }
  getTimeSheetsByUser(user) {
    return axios.post(API_URL + "time", user, { headers: authHeader() });
  }
  getSheetByWeek(week) {
    return axios.post(API_URL + "time/single", week, { headers: authHeader() });
  }
  getWeekEndings(user) {
    return axios.post(API_URL + "time/week-endings", user, {
      headers: authHeader(),
    });
  }
  getAllByWeek(week) {
    return axios.post(API_URL + "time/all-by-week", week, {
      headers: authHeader(),
    });
  }
  getStatsByUser(user) {
    return axios.post(API_URL + "/time/stats", user, {
      headers: authHeader(),
    });
  }
  adminSingle(data) {
    return axios.post(API_URL + "time/admin-single", data, {
      headers: authHeader(),
    });
  }
}

export default new TimeService();
