import React, { useState } from "react";
import authService from "../../services/auth.service";
function UpdatePassword() {
  const [updatedPass, setUpdatedPass] = useState("");
  const user = authService.getCurrentUser();
  function handleSubmit() {
    authService.updatePassword({ uid: user.id, newPass: updatedPass });
  }
  return (
    <div className="d-flex flex-column">
      <input
        type="password"
        onChange={(e) => setUpdatedPass(e.target.value)}
        className=" w-20 fancy-input"
      />
      <button onClick={handleSubmit} className="purp-btn">
        update
      </button>
    </div>
  );
}

export default UpdatePassword;
