import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import timeService from "../../services/time.service";
import authService from "../../services/auth.service";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import userService from "../../services/user.service";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import AdminSingle from "./AdminSingle";
function AdminView() {
  const navigate = useNavigate();
  const user = authService.getCurrentUser();
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [singleUser, setSingleUser] = useState("");
  const [singleView, setSingleView] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [hours, setHours] = useState({});
  const [totalHours, setTotalHours] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  function handleAdminSingle(data) {
    console.log(data);
    // navigate(
    //   `/time-sheets-admin-single/${data.startDate}/${data.endDate}/${data.user}`
    // );
    if (data) {
      setSingleUser(data.user);
      setStart(data.startDate);
      setEnd(data.endDate);
      setSingleView(true);
    } else {
      setSingleUser("");
      setStart("");
      setEnd("");
      setSingleView(false);
    }
  }
  function nthWord(str, n) {
    var m = str.match(new RegExp("^(?:\\w+\\W+){" + --n + "}(\\w+)"));

    return m && m[1];
  }
  function lookUpWeek(start, end) {
    setStartDate(start);
    setEndDate(end);
    timeService
      .getAllByWeek({ startDate: start, endDate: end })
      .then(function (res) {
        setHours(res.data);
      });
  }
  console.log(hours);
  function getTotalUserHours() {}
  getTotalUserHours();
  useEffect(() => {
    timeService
      .getWeekEndings({ id: user.id, name: user.name })
      .then(function (res) {
        let total = [];
        res.data.map((e) => {
          let tempObj = {};
          tempObj.label = e.week_ending;
          tempObj.value = e.week_ending;
          total.push(tempObj);
        });
        setWeeks(total);
      });
    userService.getTotalHours({ all: true }).then(function (res) {
      console.log(res);
      setTotalHours(res.data);
    });
  }, []);
  function handleSelect(ranges) {
    console.log(ranges);
    let { startDate, endDate } = ranges.selection;
    startDate = new Date(startDate).toLocaleDateString("en-CA");
    endDate = new Date(endDate).toLocaleDateString("en-CA");
    console.log(`${startDate} ${endDate}`);
    lookUpWeek(startDate, endDate);
  }
  return (
    <div className="flex">
      <Sidebar />
      {singleView ? (
        <AdminSingle
          handleBack={() => handleAdminSingle(false)}
          start={start}
          end={end}
          user={singleUser}
        />
      ) : (
        <div className="timesheet-overview">
          <div className="ts-head">
            <header className="header">Admin View</header>
            <div className="mt-5 mb-5 w-50 select-week-container">
              <p className="sub-header">Hours By Week Ending</p>
              <p>Start date: {startDate}</p>
              <p>End Date: {endDate}</p>
              <DateRangePicker
                months={2}
                direction="horizontal"
                isOutsideRange={() => false}
                showDateDisplay={false}
                staticRanges={[]}
                inputRanges={[]}
                ranges={[selectionRange]}
                onChange={handleSelect}
              />
            </div>

            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Spydersoft Hours</th>
                  <th>Biz Hours</th>
                  <th>Groktek Hours</th>
                  <th>Adiuvo Hours</th>
                  <th>4Core Hours</th>
                  <th>PV Hours</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(hours)
                  .sort((a, b) => {
                    return nthWord(a, 2) > nthWord(b, 2)
                      ? 1
                      : nthWord(a, 2) < nthWord(b, 2)
                      ? -1
                      : 0;
                  })
                  .map((entry, i) => {
                    let bizHours = hours[entry].Biznected || 0;
                    let grokHours = hours[entry].Groktek || 0;
                    let adiuvoHours = hours[entry].Adiuvo || 0;
                    let fourCoreHours = hours[entry]["Four Core"] || 0;
                    let spydersoftHours = hours[entry].Spydersoft || 0;
                    let properVillainsHours =
                      hours[entry]["Proper Villains"] || 0;
                    let totalHours =
                      spydersoftHours +
                      bizHours +
                      grokHours +
                      adiuvoHours +
                      fourCoreHours +
                      properVillainsHours;
                    return (
                      <tr
                        class="pointer hover-purp"
                        onClick={() =>
                          handleAdminSingle({ user: entry, startDate, endDate })
                        }
                        key={i}
                      >
                        <td>{entry}</td>
                        <td>{spydersoftHours}</td>
                        <td>{bizHours}</td>
                        <td>{grokHours}</td>
                        <td>{adiuvoHours}</td>
                        <td>{fourCoreHours}</td>
                        <td>{properVillainsHours}</td>
                        <td>{totalHours}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <div className="spacer"></div>
            <hr />
            <p className="sub-header">Users Total Hours</p>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Total Hours</th>
                </tr>
              </thead>
              <tbody>
                {totalHours.map((user) => {
                  return (
                    <tr>
                      <td>{user.user_name}</td>
                      <td>{user.hours}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminView;
