import React from "react";
function ProfileGroup(props) {
  let socials = ["twitter", "facebook-f", "tiktok", "instagram"];
  return (
    <div className="prof-input-grp">
      <span
        className={
          socials.includes(props.icon)
            ? `fab fa-${props.icon} prof-icon`
            : `fa fa-${props.icon} prof-icon`
        }
      />
      <input
        onChange={props.onChange}
        placeholder={props.placeholder}
        type="text"
        name={props.name}
        value={props.value}
        className="fancy-input"
      />
    </div>
  );
}

export default ProfileGroup;
