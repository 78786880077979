import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Select from "react-select";
import projectService from "../../services/project.service";
import authService from "../../services/auth.service";
import FontAwesome from "react-fontawesome";
import { Tab, Nav, Col, Row, Table } from "react-bootstrap";
import DayPickerInput from "react-day-picker";
import "react-day-picker/lib/style.css";
import timeService from "../../services/time.service";
function NewSheet() {
  const navigate = useNavigate();
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isFocused ? "#342d44" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  function titleCase(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }

  const selectDay = [
    {
      value: "Monday",
      label: "Monday",
    },
    {
      value: "Tuesday",
      label: "Tuesday",
    },
    {
      value: "Wednesday",
      label: "Wednesday",
    },
    {
      value: "Thursday",
      label: "Thursday",
    },
    {
      value: "Friday",
      label: "Friday",
    },
    {
      value: "Saturday",
      label: "Saturday",
    },
    {
      value: "Sunday",
      label: "Sunday",
    },
    {
      value: "Volunteer",
      label: "Volunteer",
    },
  ];
  let [user, setUser] = useState(authService.getCurrentUser());

  const [date, setDate] = useState("");
  const [holdDay, setHoldDay] = useState("");
  const [holdProjName, setHoldProjName] = useState("");
  const [holdProjID, setHoldProjID] = useState("");
  const [holdHours, setHoldHours] = useState(0);
  const [holdCompany, setHoldCompany] = useState("");
  const [projects, setProjects] = useState([]);
  const [daySelected, setDaySelected] = useState("Monday");
  const [times, setTimes] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    projectService.getProjects({ id: user.id }).then((res) => {
      let data = res.data;

      let finalArr = [];
      data.map((item) => {
        if (item.status != "completed") {
          finalArr.push({
            label: item.project_name,
            value: item.id,
            company: item.company,
          });
        }
      });
      setProjects(finalArr);
    });
  }, []);
  function addTime() {
    console.log(holdDay);
    console.log(holdProjName);
    console.log(holdProjID);
    console.log(holdHours);
    console.log(holdCompany);
    if (holdDay && holdProjName && holdHours) {
      let old = [...times];
      old.push({
        company: holdCompany,
        project: holdProjID,
        hours: holdHours,
        day: holdDay,
        project_name: holdProjName,
      });
      setTimes(old);
      setDaySelected(holdDay);
      setHoldCompany("");
      setHoldDay("");
      setHoldProjID(0);
      setHoldHours(0);
      setHoldProjName("");
      handleSubmit(null, false);
    }
  }
  function handleSubmit(e, reroute) {
    e.preventDefault();
    timeService
      .submitTime({
        uid: user.id,
        user_name: user.name,
        week_ending: date,
        times: times,
      })
      .then(function (res) {
        if (reroute) {
          navigate("/time-sheets");
        }
      })
      .catch((err) => {
        let { message } = err.response.data;
        setError(message);
      });
  }
  function handleDate(e) {
    setError("");
    const isSunday = new Date(e.target.value).getDay();
    if (isSunday == 6) {
      setDate(e.target.value);
    } else {
      setError("Please select a valid date");
      setDate("");
    }
  }

  return (
    <div className="flex">
      <Sidebar />
      <div className="timesheet-overview">
        <header className="d-flex align-items-center justify-content-between ">
          <h1 className="header">Edit Sheet</h1>
          <button
            onClick={(e) => handleSubmit(e, true)}
            className="mt-0 purp-btn ts-save"
          >
            save
          </button>
        </header>
        <div className="total-hrs-container">
          <h2 className="sub-header">
            Current Total Hours:{" "}
            {times.reduce(function (acc, obj) {
              return acc + parseFloat(obj.hours);
            }, 0) || 0}
          </h2>
        </div>
        {error ? (
          <div className="ts-errors">
            <span className="red">*</span> {error}
          </div>
        ) : null}
        <div className="week-ending-group">
          <label>Week Ending</label>
          <input
            className="fancy-input"
            required
            value={date}
            onChange={handleDate}
            type="date"
          ></input>
        </div>
        <div className="new-entry">
          <div className="new-entry-group third-container">
            <label className="ts-label">Day of the Week</label>
            <Select
              value={{ label: holdDay, value: holdDay }}
              onChange={(e) => setHoldDay(e.value)}
              styles={customStyles}
              className="new-entry-select"
              options={selectDay}
            />
          </div>
          <div className="new-entry-group">
            <label className="ts-label">Project</label>

            <Select
              onChange={(e) => {
                setHoldProjID(e.value);
                setHoldProjName(e.label);
                setHoldCompany(e.company);
              }}
              styles={customStyles}
              className="new-entry-select"
              options={projects}
            />
          </div>
          <div className="new-entry-group w-20">
            <label className="ts-label">Hours</label>

            <input
              value={holdHours}
              onChange={(e) => setHoldHours(e.target.value)}
              className=" w-100 fancy-input"
              type="number"
            ></input>
          </div>
          <div className="w-20 justify-content-center d-flex align-items-end new-entry-group text-center">
            <button onClick={addTime} className="purp-btn new-entry-submit">
              add
            </button>
          </div>
        </div>
        <div className="tab-switcher-container">
          <div className="tab-switcher-head">
            <div
              onClick={() => setDaySelected("Monday")}
              className={`${
                daySelected === "Monday" ? "active-tab" : null
              } tab-head-item`}
            >
              Monday
            </div>
            <div
              onClick={() => setDaySelected("Tuesday")}
              className={`${
                daySelected === "Tuesday" ? "active-tab" : null
              } tab-head-item`}
            >
              Tuesday
            </div>
            <div
              onClick={() => setDaySelected("Wednesday")}
              className={`${
                daySelected === "Wednesday" ? "active-tab" : null
              } tab-head-item`}
            >
              Wednesday
            </div>
            <div
              onClick={() => setDaySelected("Thursday")}
              className={`${
                daySelected === "Thursday" ? "active-tab" : null
              } tab-head-item`}
            >
              Thursday
            </div>
            <div
              onClick={() => setDaySelected("Friday")}
              className={`${
                daySelected === "Friday" ? "active-tab" : null
              } tab-head-item`}
            >
              Friday
            </div>
            <div
              onClick={() => setDaySelected("Saturday")}
              className={`${
                daySelected === "Saturday" ? "active-tab" : null
              } tab-head-item`}
            >
              Saturday
            </div>
            <div
              onClick={() => setDaySelected("Sunday")}
              className={`${
                daySelected === "Sunday" ? "active-tab" : null
              } tab-head-item`}
            >
              Sunday
            </div>
            <div
              onClick={() => setDaySelected("Volunteer")}
              className={`${
                daySelected === "Volunteer" ? "active-tab" : null
              } tab-head-item`}
            >
              Volunteer
            </div>
          </div>

          <div className="tab-content-container">
            <div className="tab-switcher-content">
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Day</th>
                    <th>Project</th>
                    <th style={{ width: "20%" }}>Hours</th>
                    <th style={{ width: "15%" }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {times.map((time, index) => {
                    if (time.day === daySelected) {
                      return (
                        <tr key={index}>
                          <td>
                            <Select
                              onChange={(e) => {
                                let newArr = [...times];
                                newArr[index].day = e.value;
                                setTimes(newArr);
                              }}
                              value={{
                                label: titleCase(time.day),
                                value: time.day,
                              }}
                              styles={customStyles}
                              options={selectDay}
                            />
                          </td>
                          <td>
                            <Select
                              onChange={(e) => {
                                let newArr = [...times];
                                newArr[index].project = e.value;
                                newArr[index].project_name = e.label;
                                setTimes(newArr);
                              }}
                              value={{
                                label: time.project_name,
                                value: time.project,
                              }}
                              styles={customStyles}
                              options={projects}
                            />
                          </td>
                          <td>
                            <input
                              onChange={(e) => {
                                let newArr = [...times];
                                newArr[index].hours = e.target.value;
                                setTimes(newArr);
                              }}
                              onKeyPress={(e) => {
                                if (e.code === "Minus") {
                                  e.preventDefault();
                                }
                              }}
                              className="fancy-input"
                              type="number"
                              defaultValue={time.hours}
                            ></input>
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                if (times.length > 1) {
                                  setTimes(
                                    times.filter((time, i) => i !== index)
                                  );
                                }
                              }}
                              className=" edit-btn"
                            >
                              delete
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSheet;
