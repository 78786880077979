import React, { useState } from "react";
import Select from "react-select";
import Sidebar from "../../components/Sidebar";
import "./request.css";
import styled, { keyframes } from "styled-components";
import AuthService from "../../services/auth.service";
import { fadeIn } from "react-animations";
import requestService from "../../services/request.service";
import { useNavigate } from "react-router-dom";
const FadeIn = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function CreateRequest() {
  const navigate = useNavigate();
  let user = AuthService.getCurrentUser().id;
  let role = AuthService.getCurrentUser().roles;

  const selectCompanies = [
    { value: "Proper Villains", label: "Proper Villains" },
    { value: "Biznected", label: "Biznected" },
    { value: "Groktek", label: "Groktek" },
    { value: "Four Core", label: "4Core" },
    { value: "Adiuvo", label: "Adiuvo" },
  ];
  const [text, setText] = useState("");
  const [company, setCompany] = useState("");
  function handleSubmit(e) {
    e.preventDefault();
    requestService
      .submitRequest({
        requestee: AuthService.getCurrentUser().name,
        user,
        company,
        request: text,
      })
      .then(function () {
        setTimeout(() => {
          navigate("/");
        }, 500);
      });
  }

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="request-container">
        <div className="flex align-items-center ">
          <header className="header">Submit a Request</header>
          {role.includes("ROLE_MODERATOR") || role.includes("ROLE_ADMIN") ? (
            <button
              onClick={() => navigate("/requests-view-all")}
              className="save-ms-btn purp-btn"
            >
              view all
            </button>
          ) : null}
        </div>
        <FadeIn>
          <div className="request-input-container">
            <div className="req-company-select">
              <label className="ts-label">Select Company</label>
              <Select
                onChange={(e) => setCompany(e.value)}
                options={selectCompanies}
              />
            </div>
            <div className="req-textarea">
              <label className="ts-label">Describe your request</label>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="req-ta w-100"
              ></textarea>
            </div>
            <div className="submit-req-container">
              <button onClick={handleSubmit} className="purp-btn">
                submit
              </button>
            </div>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default CreateRequest;
