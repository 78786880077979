import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import projectService from "../../services/project.service";
import userService from "../../services/user.service";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import MilestonePopup from "../../components/MilestonePopup";
import FontAwesome from "react-fontawesome";
import MilestoneInput from "../../components/MilestoneInput";
import Loading from "../../components/Loading";
import authService from "../../services/auth.service";
const FadeIn = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function MileStones() {
  const templates = [
    { label: "Website", value: "website" },
    { label: "Headshot", value: "headshot" },
    { label: "Onboarding", value: "onboarding" },
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const [show, setShow] = useState(false);

  const [contributors, setContributors] = useState([]);
  const [dueDate, setDueDate] = useState("");
  const [formFields, setFormFields] = useState({});

  const [milestones, setMilestones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [template, setTemplate] = useState(templates[0]);
  const [currentMilestone, setCurrentMilestone] = useState({});
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#342d44" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };

  const templateObj = {
    website: [
      "Create Header",
      "Create Footer",
      "Create Home Page",
      "Create About Us Page",
      "Create Services Page",
      "Create Contact Us Page",
      "Ensure Mobile Responsiveness",
      "Double Check Links",
      "Animations",
      "Implement reCAPTCHA on ALL forms",
      "Disable comments on posts",
    ],
    headshot: [
      "Set up camera and tripod",
      "Take several pictures",
      "Choose Headshot",
      "Delete Background",
      "Add to company background",
      "Send headshot",
      "Edits",
    ],
    onboarding: [
      "New Hire Welcome Email",
      "Setup Company Email Address with New Hire Paperwork",
      "Make copies of ID(s)",
      "E-Verify",
      "Add to Microsoft Teams",
      "Outlook Calendar",
      "KISI",
      "3CX",
      "Mugshots",
      "OMiS",
      "Q&A",
      "Paychex",
    ],
  };

  const filterOptions = [
    { label: "priority", value: "priority" },
    { label: "status", value: "status" },
  ];
  const statusOptions = [
    {
      label: "Not Started",
      value: "Not Started",
    },
    {
      label: "In Progress",
      value: "In Progress",
    },
    {
      label: "Client Review",
      value: "Client Review",
    },
    {
      label: "In Maintenance",
      value: "In Maintenance",
    },
    { label: "Complete", value: "Complete" },
    {
      label: "On Pause",
      value: "On Pause",
    },
  ];
  const prioOptions = [
    {
      label: "Low",
      value: "Low",
    },
    {
      label: "Medium",
      value: "Medium",
    },
    {
      label: "High",
      value: "High",
    },
  ];
  const conversionPrio = {
    low: 1,
    medium: 2,
    high: 3,
    Low: 1,
    Medium: 2,
    High: 3,
  };
  const conversionStatus = {
    "not started": 1,
    "in progress": 2,
    "client review": 3,
    "in maintenance": 4,
    complete: 5,
    "on pause": 6,
  };
  const handleClose = () => {
    setShow(false);
    setCurrentMilestone({});
  };
  const handleShow = () => setShow(true);
  function saveMilestone() {
    projectService
      .newMilestone({ pid: id, milestones })
      .then(function (res) {});
  }
  function titleCase(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
  function handleSelect(e, name, val) {
    let tempMilestones = [...milestones];
    tempMilestones.forEach((ms) => {
      if (ms.milestone_name === name) {
        ms[val] = { label: e.value, value: e.value };
      }
    });
    setMilestones(tempMilestones);
  }
  function deleteMs(e) {
    let msName = e.target.getAttribute("associate");
    let msId = e.target.getAttribute("msId");

    projectService.deleteMs({ msName, pid: id });
    let tempMilestones = [...milestones];
    tempMilestones = tempMilestones.filter((i) => {
      return i.milestone_name != msName;
    });
    setMilestones(tempMilestones);
  }
  function addMilestone(msInput) {
    if (msInput != "") {
      setMilestones([
        ...milestones,
        {
          milestone_name: msInput,
          priority: [{ label: "Low" }, { value: "low" }],
          status: [{ label: "Not Started" }, { value: "not started" }],
          assigned_to: [{ label: "", value: "" }],
        },
      ]);
      saveMilestone();
    }
  }
  function filterChange(e) {
    let tempArr = [...milestones];
    if (e.value == "priority") {
      tempArr.sort((a, b) => {
        let prioLevelA = conversionPrio[a.priority.value.toLowerCase()];
        let prioLevelB = conversionPrio[b.priority.value.toLowerCase()];
        return prioLevelA - prioLevelB;
      });
      setMilestones(tempArr);
    } else if (e.value === "status") {
      tempArr.sort((a, b) => {
        let statusLevelA = conversionStatus[a.status.value.toLowerCase()];
        let statusLevelB = conversionStatus[b.status.value.toLowerCase()];
        return statusLevelA - statusLevelB;
      });
      setMilestones(tempArr);
    }
  }
  function generateTemplate() {
    let whichTemplate = template.toLowerCase();
    let totalArr = [];
    templateObj[whichTemplate].forEach((ms) => {
      let tempObj = {
        milestone_name: ms,
        priority: [{ label: "Low" }, { value: "low" }],
        status: [{ label: "Not Started" }, { value: "not started" }],
        assigned_to: [{ label: "", value: "" }],
      };
      totalArr.push(tempObj);
    });
    setMilestones(totalArr);
  }
  function handleModalSubmit(data) {
    let old = [...milestones];
    old.map((u) => {
      if (u.id === data.id) {
        u.milestone_name = data.title;
        u.description = data.description;
      }
    });

    setMilestones(old);

    setShow(false);
  }
  useEffect(() => {
    userService.getAllUsers().then(function (res) {
      let tempArr = [];
      res.data.map((name) => {
        tempArr.push({
          label: titleCase(name),
          value: name.toLowerCase(),
        });
      });
    });

    projectService.getMilestones({ pid: id }).then(function (res) {
      let newArr = [];
      res.data.map((entry) => {
        newArr.push({
          milestone_name: entry.milestone_name,
          priority: { label: entry.priority, value: entry.priority },
          status: { label: entry.status, value: entry.status },
          assigned_to: { label: entry.assigned_to, value: entry.assigned_to },
          description: entry.description,
          due_date: entry.due_date,
          id: entry.id,
        });
      });

      setMilestones(newArr);
      setLoading(false);
    });
    projectService.getProjectById(id).then(function (res) {
      let project = res.data.project[0];
      let tempContrib = [];
      project.users.map((user) => {
        tempContrib.push({ label: user.name, value: user.name });
      });
      let {
        id,
        company,
        client_name,
        client_phone,
        client_email,
        client_address,
        project_name,
        start_date,
        estimated_end_date,
        tasks,
        description,
        additional_notes,
        system,
        version,
      } = project;
      setFormFields({
        id,
        client_name,
        client_phone,
        client_email,
        client_address,
        project_name,
        start_date,
        estimated_end_date,
        tasks,
        description,
        additional_notes,
        system,
        version,
      });
      setContributors(tempContrib);
    });

    // projectService.getProjectById(id);
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="milestones-container">
        <header className=" ms-header">
          <h1 className="header"> Milestones for {formFields.project_name}</h1>
          <button
            onClick={() => {
              saveMilestone();
              navigate("/projects-view");
            }}
            className="save-ms-btn purp-btn"
          >
            save
          </button>
        </header>

        <div className="create-milestone-container">
          <MilestonePopup
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
            title={currentMilestone.title}
            status={
              currentMilestone.status ? currentMilestone.status.label : ""
            }
            priority={
              currentMilestone.priority ? currentMilestone.priority.label : ""
            }
            contributor={
              currentMilestone.contributor
                ? currentMilestone.contributor.label
                : ""
            }
            id={currentMilestone.id}
            description={currentMilestone.description}
            handleSubmit={handleModalSubmit}
            buttonText="save"
          />
          <div className="">
            <label className="ts-label">Generate a Template</label>
            <div className=" align-items center">
              <Select
                styles={customStyles}
                onChange={(e) => setTemplate(e.value)}
                className="w-20"
                options={templates}
              />
              <button
                onClick={generateTemplate}
                className="mt-2 ms-btn purp-btn"
              >
                generate
              </button>
            </div>
          </div>
          <div className="input-group">
            <label className="ts-label">Create a new milestone</label>
            <MilestoneInput addMilestone={addMilestone} />
          </div>
        </div>

        <div className="table-container">
          <p className="bold sub-header">Filter Table</p>
          <Select
            onChange={filterChange}
            options={filterOptions}
            styles={customStyles}
            className="w-20"
          ></Select>
          {/* {loading ? (
              <Loading />
            ) : ( */}
          <Table className="milestone-table">
            <thead>
              <tr>
                <th></th>
                <th className="milestone-head">Milestone</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Contributor</th>
                <th>Due</th>
                {authService.getCurrentUser().roles.includes("ROLE_ADMIN") ? (
                  <th>Delete</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {milestones.map((u, i) => {
                if (u.status.value != "Complete") {
                  return (
                    <tr key={u.id}>
                      <td
                        className="pointer"
                        onClick={() => {
                          setCurrentMilestone({
                            title: u.milestone_name,
                            priority: u.priority,
                            status: u.status,
                            contributor: u.assigned_to,
                            description: u.description,
                            id: u.id,
                          });
                          setShow(true);
                        }}
                      >
                        <FontAwesome
                          className="nav-icon"
                          name="edit"
                          title="Edit Milestone"
                          size="1x"
                          style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
                        />
                      </td>
                      <td>{u.milestone_name}</td>
                      <td>
                        <Select
                          styles={customStyles}
                          className="milestone-select"
                          onChange={(e) =>
                            handleSelect(e, u.milestone_name, "priority")
                          }
                          options={prioOptions}
                          value={u.priority}
                        />
                      </td>
                      <td>
                        <Select
                          styles={customStyles}
                          className="milestone-select"
                          onChange={(e) =>
                            handleSelect(e, u.milestone_name, "status")
                          }
                          options={statusOptions}
                          value={u.status}
                        />
                      </td>
                      <td>
                        <Select
                          styles={customStyles}
                          className="milestone-select"
                          value={u.assigned_to}
                          onChange={(e) =>
                            handleSelect(e, u.milestone_name, "assigned_to")
                          }
                          options={contributors}
                        />
                      </td>
                      <td>
                        {" "}
                        <input
                          value={u.due_date}
                          onChange={(e) => {
                            console.log(e.target.value);
                            let newDate = [...milestones];
                            newDate[i].due_date = e.target.value;
                            setMilestones(newDate);
                          }}
                          type="date"
                          className="fancy-input"
                        ></input>
                      </td>
                      {authService
                        .getCurrentUser()
                        .roles.includes("ROLE_ADMIN") ? (
                        <td className="center">
                          <button
                            onClick={deleteMs}
                            msId={i}
                            associate={u.milestone_name}
                            className="edit-btn"
                          >
                            delete
                          </button>
                        </td>
                      ) : null}
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <hr />
          <h2 className="mt-5 sub-header">Completed Milestones</h2>
          <Table className="milestone-table">
            <thead>
              <tr>
                <th style={{ width: "5%" }}>#</th>
                <th className="completed-ms-head">Milestone</th>
                <th>Completed By</th>
                <th>revert</th>.
              </tr>
            </thead>
            <tbody>
              {milestones.map((u, i) => {
                if (u.status.value === "Complete")
                  return (
                    <tr key={u.id}>
                      <td>{u.id}</td>
                      <td>{u.milestone_name}</td>
                      <td>{u.assigned_to.value}</td>
                      <td>
                        <button
                          onClick={() => {
                            let newArr = [...milestones];
                            newArr[i].status = {
                              label: "Not Started",
                              value: "Not Started",
                            };
                            setMilestones(newArr);
                          }}
                          className="edit-btn"
                        >
                          revert
                        </button>
                      </td>
                    </tr>
                  );
              })}
            </tbody>
          </Table>

          <div className="milestones-mobile">
            {milestones.map((u, i) => {
              return (
                <div key={u.id} className="mt-4 mb-4 milestone-mobile">
                  <h2 className="text-center white sub-header">
                    {u.milestone_name}
                  </h2>
                  <p className="white bold">Priority</p>
                  <Select
                    className="milestone-select"
                    onChange={(e) =>
                      handleSelect(e, u.milestone_name, "priority")
                    }
                    options={prioOptions}
                    value={u.priority}
                  />
                  <p className="mt-2 white bold">Status</p>
                  <Select
                    className="milestone-select"
                    onChange={(e) =>
                      handleSelect(e, u.milestone_name, "status")
                    }
                    options={statusOptions}
                    value={u.status}
                  />
                  <p className="mt-2 white bold">Assigned To</p>
                  <Select
                    className="milestone-select"
                    value={u.assigned_to}
                    onChange={(e) =>
                      handleSelect(e, u.milestone_name, "assigned_to")
                    }
                    options={contributors}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MileStones;
