import axios from "axios";
import authHeader from "./auth-header";

const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "http://fomis.herokuapp.com/api/";

class RequestService {
  submitRequest(data) {
    return axios.post(API_URL + "requests/new", data, {
      headers: authHeader(),
    });
  }
  getAll(uid) {
    return axios.post(API_URL + "requests/all", uid, {
      headers: authHeader(),
    });
  }
  updateStatus(data) {
    return axios.post(API_URL + "requests/update", data, {
      headers: authHeader(),
    });
  }
  getUnreadCount(user) {
    return axios.post(API_URL + "requests/unread", user, {
      headers: authHeader(),
    });
  }
}

export default new RequestService();
