import React from "react";
import Sidebar from "../../components/Sidebar";
import User from "../../components/User";
function Directory() {
  return (
    <div className="flex w-100">
      <Sidebar />
      <div className="user-card-container">
        <User />
        <User />
        <User />
        <User />
        <User />
        <User />
      </div>
    </div>
  );
}

export default Directory;
