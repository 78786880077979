import React, { useState, useEffect } from "react";

import Sidebar from "../../components/Sidebar";
import projectService from "../../services/project.service";
import userService from "../../services/user.service";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import authService from "../../services/auth.service";
import Popup from "../../components/Popup";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function EditExisting() {
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isFocused ? "#342d44" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  const navigate = useNavigate();
  const selectCompanies = [
    { value: "Biznected", label: "Biznected" },
    { value: "Groktek", label: "Groktek" },
    { value: "Four Core", label: "4Core" },
    { value: "Adiuvo", label: "Adiuvo" },
    { value: "Proper Villains", label: "Proper Villains" },
    { value: "Spydersoft", label: "Spydersoft" },
  ];
  const user = authService.getCurrentUser().id;
  const [show, setShow] = useState(false);

  const [users, setUsers] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [company, setCompany] = useState();
  const [formFields, setFormFields] = useState({});
  let [equipment, setEquipment] = useState([
    { name: "", qty: "", description: "" },
  ]);

  function titleCase(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
  const { id } = useParams();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleCompany(e) {
    setCompany(e.value);
  }
  function handleContributors(e) {
    setContributors(e);
  }
  function handleFormChange(e) {
    const { name, value } = e.target;
    setFormFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    let tempArr = { ...formFields };
    console.log(formFields);
    tempArr.equipment = equipment;
    tempArr.company = company;
    tempArr.contributors = contributors;
    projectService
      .updateProject({ user: user, project: tempArr })
      .then(function (res) {
        navigate("/projects-view");
      });
  }
  function deleteProj() {
    projectService.deleteProject({ pid: id }).then(function () {
      navigate("/projects-view");
    });
  }
  useEffect(() => {
    userService.getAllUsers().then(function (res) {
      let tempArr = [];
      res.data.map((name) => {
        tempArr.push({
          label: titleCase(name),
          value: name.toLowerCase(),
        });
      });
      setUsers(tempArr);
    });
    projectService.getProjectById(id).then(function (res) {
      let project = res.data.project[0];
      let equipment = res.data.equipment;
      let tempContrib = [];
      project.users.forEach((user) => {
        tempContrib.push({ label: user.name, value: user.name });
      });
      let {
        id,

        client_name,
        client_phone,
        client_email,
        client_address,
        project_name,
        start_date,
        estimated_end_date,
        tasks,
        description,
        additional_notes,
        system,
        version,
        internal,
      } = project;
      let comp = project.company;
      console.log(comp);
      setFormFields({
        id,
        client_name,
        client_phone,
        client_email,
        client_address,
        project_name,
        start_date,
        estimated_end_date,
        tasks,
        description,
        additional_notes,
        system,
        version,
        internal,
      });
      setContributors(tempContrib);
      setCompany(comp);
      let tempArr = [];
      equipment.map((unit) => {
        tempArr.push({
          name: unit.equipment,
          qty: unit.quantity,
          description: unit.description,
        });
      });
      setEquipment(tempArr);
    });
    // projectService.getProjectById(id);
  }, []);
  console.log(formFields);
  console.log(company);
  return (
    <div className="create-new-container">
      <Sidebar />
      <div className="break form-container">
        <Popup
          handleClose={handleClose}
          handleShow={handleShow}
          bodyContent="Hold it right there! are you sure you want to delete this project?"
          show={show}
          redText={"this action cannot be undone"}
          title="Confirm Delete Project"
          buttonText="delete"
          handleSubmit={deleteProj}
        ></Popup>
        <div className="ms-header align-items-center">
          <h1 className="mb-5 header">Edit Project</h1>
          <button onClick={handleShow} className="save-ms-btn purp-btn">
            Delete Project
          </button>
        </div>
        <Fade>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                Select Company <span className="required">*</span>
              </label>
              <Select
                styles={customStyles}
                value={{ label: company, value: company }}
                onChange={handleCompany}
                options={selectCompanies}
              />
            </div>
            <div className="form-group">
              <label>
                Select Contributors <span className="required">*</span>
              </label>
              <Select
                styles={customStyles}
                value={contributors}
                onChange={handleContributors}
                isMulti={true}
                options={users}
              />
            </div>
            <div className="form-group">
              <label>
                Project Name <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={handleFormChange}
                defaultValue={formFields.project_name}
                placeholder="example project"
                id="project_name"
                name="project_name"
                type="text"
              ></input>
              <div>
                <label>Internal Project</label>
                <input
                  value={formFields.internal}
                  name="internal"
                  onChange={(e) =>
                    setFormFields((prevState) => ({
                      ...prevState,
                      internal: !prevState.internal,
                    }))
                  }
                  type="checkbox"
                ></input>
              </div>
            </div>
            <div className="form-group">
              <label>
                Client Name <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                defaultValue={formFields.client_name}
                onChange={handleFormChange}
                placeholder="first and last name"
                id="client_name"
                name="client_name"
                type="text"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Phone</label>
              <input
                className="fancy-input"
                onChange={handleFormChange}
                defaultValue={formFields.client_phone}
                id="client_phone"
                name="client_phone"
                type="tel"
                maxLength={14}
                placeholder="(111) 111-1111"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Email</label>
              <input
                className="fancy-input"
                onChange={handleFormChange}
                defaultValue={formFields.client_email}
                placeholder="example@email.com"
                id="client_email"
                name="client_email"
                type="email"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Address</label>
              <input
                className="fancy-input"
                onChange={handleFormChange}
                defaultValue={formFields.client_address}
                placeholder="1234 example blvd"
                id="client_address"
                name="client_address"
                type="text"
              ></input>
            </div>
            <div className="form-group">
              <label>
                Start Date <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={handleFormChange}
                defaultValue={formFields.start_date}
                id="start_date"
                name="start_date"
                type="date"
              ></input>
            </div>
            <div className="form-group">
              <label>
                Estimated Due Date <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={handleFormChange}
                defaultValue={formFields.estimated_end_date}
                id="estimated_end_date"
                name="estimated_end_date"
                type="date"
              ></input>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                onChange={handleFormChange}
                defaultValue={formFields.description}
                rows="3"
                id="description"
                name="description"
              ></textarea>
            </div>
            <div className="form-group">
              <label>Additional Notes</label>
              <textarea
                onChange={handleFormChange}
                defaultValue={formFields.additional_notes}
                rows="3"
                id="additional_notes"
                name="additional_notes"
              ></textarea>
            </div>
            <div className="form-group equipment-group">
              {equipment.map((item, index) => {
                return (
                  <div key={index} className="w-100">
                    <div className="equipment-top w-40">
                      <input
                        className="equipment-name"
                        type="text"
                        placeholder="Name"
                        value={equipment[index].name}
                        onChange={(e) => {
                          equipment[index].name = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></input>
                      <input
                        className="equipment-qty"
                        placeholder="Qty"
                        type="number"
                        min={0}
                        value={equipment[index].qty}
                        onChange={(e) => {
                          equipment[index].qty = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></input>
                    </div>
                    <div>
                      <textarea
                        className="equipment-description"
                        placeholder="Optional desctiption..."
                        value={equipment[index].description}
                        onChange={(e) => {
                          equipment[index].description = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></textarea>
                    </div>
                  </div>
                );
              })}
              <button
                className="add-equipment-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setEquipment([
                    ...equipment,
                    { name: "", qty: "", description: "" },
                  ]);
                }}
              >
                +
              </button>
            </div>

            <div className="form-group submit-group">
              <button type="submit">update</button>
            </div>
          </form>
        </Fade>
      </div>
    </div>
  );
}

export default EditExisting;
