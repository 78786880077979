import axios from "axios";
import authHeader from "./auth-header";
const API_URL = window.location.href.includes("local")
  ? "http://localhost:8080/api/"
  : "http://fomis.herokuapp.com/api/";

class ProjectService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }
  submitProject(data) {
    return axios.post(API_URL + "add-project", data, { headers: authHeader() });
  }
  getProjects(user) {
    return axios.post(API_URL + "projects", user, { headers: authHeader() });
  }
  updateStatus(project) {
    return axios.post(API_URL + "projects/update-status", project, {
      headers: authHeader(),
    });
  }
  getProjectById(id) {
    return axios.post(
      API_URL + "projects/project-by-id",
      { id: id },
      {
        headers: authHeader(),
      }
    );
  }
  updateProject(project) {
    return axios.post(API_URL + "projects/update-project", project, {
      headers: authHeader(),
    });
  }
  getProjectsByCompany(company) {
    return axios.post(API_URL + "projects/projects-by-company", company, {
      headers: authHeader(),
    });
  }
  getCompletedProjects(user) {
    return axios.post(API_URL + "projects/completed-projects", user, {
      headers: authHeader(),
    });
  }
  deleteProject(id) {
    return axios.post(API_URL + "projects/delete", id, {
      headers: authHeader(),
    });
  }
  getMilestones(pid) {
    return axios.post(API_URL + "projects/milestones", pid, {
      headers: authHeader(),
    });
  }
  newMilestone(data) {
    return axios.post(API_URL + "projects/milestone/add", data, {
      headers: authHeader(),
    });
  }
  deleteMs(data) {
    return axios.post(API_URL + "projects/milestone/delete", data, {
      headers: authHeader(),
    });
  }
  updateBilling(data) {
    return axios.post(API_URL + "projects/update-billing", data, {
      headers: authHeader(),
    });
  }
  byUsername(data) {
    return axios.post(API_URL + "projects/projects-by-username", data, {
      headers: authHeader(),
    });
  }
}
export default new ProjectService();
