import React, { useEffect, useState } from "react";
import NavItem from "./NavItem";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";
import requestService from "../services/request.service";

function Sidebar() {
  const [unreadRequests, setUnreadRequests] = useState(0);
  const user = authService.getCurrentUser();
  useEffect(() => {
    requestService.getUnreadCount({ uid: user.id }).then(function (res) {
      setUnreadRequests(res.data.length);
    });
  }, []);

  const navigate = useNavigate();
  return (
    <div className="sidebar">
      <div className="vert-sidebar">
        <img
          onClick={() => navigate("/")}
          className="nav-logo"
          src="https://i.postimg.cc/WzpxHmQh/logo.png"
        ></img>

        <NavItem link="/account" header="Account" icon="user" />
        <NavItem link="/projects" header="Projects" icon="clipboard-list" />
        <NavItem link="/time-sheets" header="Time Sheets" icon="table" />
        {/* <NavItem link="/tasks" header="My Tasks" icon="list" /> */}
        <NavItem
          link="/requests"
          header="Submit Request"
          notification={unreadRequests > 0 ? unreadRequests.toString() : null}
          icon="arrow-alt-circle-up"
        />
        <NavItem link="/logout" header="Log Out" icon="door-open" />
        {/* <NavItem link="/calendar" header="Calendar" icon="calendar-alt" /> */}
      </div>
      <div className="horz-sidebar">
        <NavItem link="/account" header="Account" icon="user" />
        <NavItem link="/time-sheets" header="Time Sheets" icon="table" />
        <NavItem link="/projects" header="Projects" icon="clipboard-list" />
        {/* <NavItem link="/tasks" header="My Tasks" icon="list" /> */}
        <NavItem
          link="/requests"
          header="Requests"
          icon="arrow-alt-circle-up"
        />
        <NavItem link="/calendar" header="Calendar" icon="calendar-alt" />
      </div>
    </div>
  );
}

export default Sidebar;
