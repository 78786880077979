import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Sidebar from "../../components/Sidebar";
import projectService from "../../services/project.service";
import userService from "../../services/user.service";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

const Fade = styled.div`
  animation: 0.5s ${keyframes`${fadeIn}`};
`;
function CreateNew() {
  const customStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        backgroundColor: isFocused ? "#342d44" : null,
        color: isFocused ? "white" : "#333333",
      };
    },
  };
  const navigate = useNavigate();
  const selectCompanies = [
    { value: "Biznected", label: "Biznected" },
    { value: "Groktek", label: "Groktek" },
    { value: "Four Core", label: "4Core" },
    { value: "Adiuvo", label: "Adiuvo" },
    { value: "Proper Villains", label: "Proper Villains" },
    { value: "Spydersoft", label: "Spydersoft" },
  ];
  const [recurring, setRecurring] = useState(false);
  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [company, setCompany] = useState("biznected");
  let [equipment, setEquipment] = useState([
    { name: "", qty: "", description: "" },
  ]);
  const submitNewProject = (data) => {
    projectService
      .submitProject(data)
      .then(function () {
        navigate("/projects");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function titleCase(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
  useEffect(() => {
    userService.getAllUsers().then(function (res) {
      let tempArr = [];
      res.data.map((name) => {
        tempArr.push({
          label: titleCase(name),
          value: name.toLowerCase(),
        });
      });
      setUsers(tempArr);
    });
  }, []);

  const initialValues = {
    // start general
    client_name: "",
    internal: false,
    client_phone: "",
    client_email: "",
    client_address: "",
    project_name: "",
    start_date: "",
    estimated_end_date: "",
    tasks: [],
    description: "",
    additional_notes: "",
    system: "",
    version: "",
  };
  function handleCompany(e) {
    setCompany(e.value);
  }
  function handleContributors(e) {
    setContributors(e);
  }
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setError("");
      let tempArr = values;
      if (equipment[0].name.length > 0) {
        tempArr.equipment = equipment;
      }
      tempArr.company = company;
      tempArr.contributors = contributors;
      tempArr.contributors.length > 0
        ? submitNewProject(tempArr)
        : setError("Must select at least one contributor");
    },
  });

  return (
    <div className="create-new-container">
      <Sidebar />
      <div className="break form-container">
        <h1 className="header">New Project</h1>
        {error ? <div className="proj-error">{error}</div> : null}
        <Fade>
          <form className="add-new-form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label>
                Select Company <span className="required">*</span>
              </label>
              <Select
                styles={customStyles}
                onChange={handleCompany}
                options={selectCompanies}
              />
            </div>
            <div className="form-group">
              <label>
                Select Contributors <span className="required">*</span>
              </label>
              <Select
                styles={customStyles}
                onChange={handleContributors}
                isMulti={true}
                options={users}
              />
            </div>
            <a
              target="_blank"
              href="http://properv8.sg-host.com/wp-content/uploads/2022/03/Wisconsin-Sales-Tax-Exemption-Form-S-211-CDL.pdf"
            />
            <div className="form-group">
              <label>
                Project Name <span className="required">*</span>
              </label>
              <div className="justify-content-between w-100 flex">
                <input
                  className="p-name-input fancy-input"
                  onChange={formik.handleChange}
                  placeholder="example project"
                  id="project_name"
                  name="project_name"
                  type="text"
                ></input>
              </div>
              <label className="mt-2">Internal Project?</label>
              <div className="align-items-start d-flex flex-column">
                <input
                  className="fancy-input"
                  name="internal"
                  onChange={formik.handleChange}
                  type="checkbox"
                ></input>
              </div>
            </div>
            <div className="form-group">
              <label>
                Client Name <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                placeholder="first and last name"
                id="client_name"
                name="client_name"
                type="text"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Phone</label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                id="client_phone"
                name="client_phone"
                type="tel"
                maxLength={14}
                placeholder="(111) 111-1111"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Email</label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                placeholder="example@email.com"
                id="client_email"
                name="client_email"
                type="email"
              ></input>
            </div>
            <div className="form-group">
              <label>Client Address</label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                placeholder="1234 example blvd"
                id="client_address"
                name="client_address"
                type="text"
              ></input>
            </div>
            <div className="form-group">
              <label>
                Start Date <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                id="start_date"
                name="start_date"
                type="date"
              ></input>
            </div>
            <div className="form-group align-items-start">
              <label>
                Estimated Due Date <span className="required">*</span>
              </label>
              <input
                className="fancy-input"
                onChange={formik.handleChange}
                id="estimated_end_date"
                name="estimated_end_date"
                type="date"
              ></input>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                onChange={formik.handleChange}
                rows="3"
                id="description"
                name="description"
              ></textarea>
            </div>
            <div className="form-group">
              <label>Additional Notes</label>
              <textarea
                onChange={formik.handleChange}
                rows="3"
                id="additional_notes"
                name="additional_notes"
              ></textarea>
            </div>
            <div className="form-group equipment-group">
              {equipment.map((item, index) => {
                return (
                  <div key={index} className="w-100">
                    <div className="equipment-top w-40">
                      <input
                        className="fancy-input equipment-name"
                        type="text"
                        placeholder="Name"
                        value={equipment[index].name}
                        onChange={(e) => {
                          equipment[index].name = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></input>
                      <input
                        className="fancy-input equipment-qty"
                        placeholder="Qty"
                        type="number"
                        min={0}
                        value={equipment[index].qty}
                        onChange={(e) => {
                          equipment[index].qty = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></input>
                    </div>
                    <div>
                      <textarea
                        className="equipment-description"
                        placeholder="Optional desctiption..."
                        value={equipment[index].description}
                        onChange={(e) => {
                          equipment[index].description = e.target.value;
                          setEquipment([...equipment]);
                        }}
                      ></textarea>
                    </div>
                  </div>
                );
              })}
              <button
                className="add-equipment-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setEquipment([
                    ...equipment,
                    { name: "", qty: "", description: "" },
                  ]);
                }}
              >
                +
              </button>
            </div>

            <div className="form-group submit-group">
              <button type="submit">submit</button>
            </div>
          </form>
        </Fade>
      </div>
    </div>
  );
}

export default CreateNew;
