import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import LoginPage from "../pages/login/LoginPage";
import Home from "../pages/home/HomePage";
import Projects from "../pages/projects/Projects";
import CreateNew from "../pages/projects/CreateNew";
import View from "../pages/projects/View";
import EditExisting from "../pages/projects/EditExisting";
import TimesheetSelect from "../pages/timesheets/TimesheetSelect";
import NewSheet from "../pages/timesheets/NewSheet";
import EditSheet from "../pages/timesheets/EditSheet";
import Account from "../pages/account/Account";
import Board from "../pages/boards/Board";
import CreateRequest from "../pages/requests/CreateRequest";
import TaskOverview from "../pages/tasks/TaskOverview";
import Calendar from "../pages/calendar/Calendar";
import MileStones from "../pages/projects/Milestones";
import ViewRequest from "../pages/requests/ViewRequest";
import Logout from "../components/Logout";
import authService from "./auth.service";
import AdminView from "../pages/timesheets/AdminView";
import Completed from "../pages/payment/Completed";
import Billing from "../pages/payment/Billing";
import UpdatePassword from "../pages/account/UpdatePassword";
import AdminSingle from "../pages/timesheets/AdminSingle";
import Directory from "../pages/account/Directory";
function CustomRouter(props) {
  let user = authService.getCurrentUser() || null;
  const location = useLocation();
  useEffect(() => {
    props.setPathname(location.pathname);
  }, [location]);
  if (!user) {
    return (
      <Routes>
        <Route path="*" element={<LoginPage />} />
      </Routes>
    );
  } else {
    return (
      <>
        <Routes>
          <Route
            key={document.location.href}
            path="/login"
            element={<LoginPage />}
          />
          <Route key={document.location.href} path="/" element={<Home />} />
          <Route
            key={document.location.href}
            path="/projects"
            element={<Projects />}
          />
          <Route
            key={document.location.href}
            path="/projects-create-new"
            element={<CreateNew />}
          />
          <Route
            key={document.location.href}
            path="/projects-view"
            element={<View />}
          />
          <Route
            key={document.location.href}
            path="/projects/edit/:id"
            element={<EditExisting />}
          />
          <Route
            key={document.location.href}
            path="/time-sheets/edit/:week"
            element={<EditSheet />}
          />
          <Route
            key={document.location.href}
            path="/time-sheets"
            element={<TimesheetSelect />}
          />
          <Route
            key={document.location.href}
            path="/time-sheets-create-new"
            element={<NewSheet />}
          />
          <Route
            key={document.location.href}
            path="time-sheets-admin"
            element={<AdminView />}
          />
          <Route
            key={document.location.href}
            path="/account"
            element={<Account />}
          />
          <Route
            key={document.location.href}
            path="/boards-biznected"
            element={<Board company="biznected" />}
          />
          <Route
            key={document.location.href}
            path="/boards-4-core"
            element={<Board company="four-core" />}
          />
          <Route
            key={document.location.href}
            path="/boards-groktek"
            element={<Board company="groktek" />}
          />
          <Route
            key={document.location.href}
            path="boards-adiuvo"
            element={<Board company="adiuvo" />}
          />
          <Route
            key={document.location.href}
            path="boards-spydersoft"
            element={<Board company="spydersoft" />}
          />
          <Route
            key={document.location.href}
            path="/requests"
            element={<CreateRequest />}
          />
          <Route
            key={document.location.href}
            path="/requests-view-all"
            element={<ViewRequest />}
          />
          <Route
            key={document.location.href}
            path="/tasks"
            element={<TaskOverview />}
          />
          <Route
            key={document.location.href}
            path="/projects/milestone/:id"
            element={<MileStones />}
          />
          <Route
            key={document.location.href}
            path="/logout"
            element={<Logout />}
          />
          <Route
            key={document.location.href}
            path="/completed-projects"
            element={<Completed />}
          />
          <Route
            key={document.location.href}
            path="/billing"
            element={<Billing />}
          />
          <Route
            key={document.location.href}
            path="/update-password"
            element={<UpdatePassword />}
          />
          <Route
            key={document.location.href}
            path="/time-sheets-admin-single/:start/:end/:user"
            element={<AdminSingle />}
          />
          <Route path="/directory" element={<Directory />} />
        </Routes>
      </>
    );
  }
}

export default CustomRouter;
