import CustomRouter from "./services/CustomRouter";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import authService from "./services/auth.service";
import { Navigate } from "react-router-dom";

function App() {
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  function setPathname() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = parseJwt(user.accessToken);

      if (!decodedJwt || decodedJwt.exp * 1000 < Date.now()) {
        logout();
      }
    } else if (window.location.pathname != "/login") {
      window.location.href = "/login";
    }
  }

  function logout() {
    authService.logout();
    Navigate("/login");
  }

  return (
    <div onChange={setPathname} className="app">
      <CustomRouter setPathname={setPathname} />
    </div>
  );
}

export default App;
