import React from "react";
import Sidebar from "../../components/Sidebar";

function TaskOverview() {
  return (
    <div className="flex">
      <Sidebar />
    </div>
  );
}

export default TaskOverview;
