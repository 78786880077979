import React, { useState } from "react";

function TextInput(props) {
  const [msInput, setmsInput] = useState("");
  return (
    <>
      <input
        value={msInput}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.addMilestone(msInput);
            setmsInput("");
          }
        }}
        onChange={(e) => setmsInput(e.target.value)}
        className="w-20 fancy-input"
        placeholder="milestone name"
      ></input>
      <button
        onClick={() => {
          props.addMilestone(msInput);
          setmsInput("");
        }}
        className="mt-2 ms-btn purp-btn"
      >
        create
      </button>
    </>
  );
}

export default TextInput;
