import React, { useState, useEffect } from "react";
import "./homepage.css";
import AuthService from "../../services/auth.service";
import projectService from "../../services/project.service";
import Sidebar from "../../components/Sidebar";
import Loading from "../../components/Loading";
import { Table } from "react-bootstrap";
import Pi from "../../components/Pi";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
import userService from "../../services/user.service";
const FadeAnimation = styled.div`
  animation: 0.5s ${keyframes`${fadeInUp}`};
`;
function HomePage() {
  const navigate = useNavigate();

  const user = AuthService.getCurrentUser();
  const [projects, setProjects] = useState([]);
  const [pNotifications, setPNotifications] = useState([]);
  const [mNotifications, setMNotifications] = useState([]);
  const loading = false;

  function titleCase(string) {
    return string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  }
  useEffect(() => {
    if (user) {
      projectService.getProjects({ id: user.id }).then((res) => {
        let toBeSorted = [...res.data];

        toBeSorted.sort((a, b) => {
          let aDate = new Date(a.estimated_end_date);
          let bDate = new Date(b.estimated_end_date);
          return aDate == "Invalid Date" || bDate === "Invalid Date"
            ? 1
            : bDate > aDate
            ? 1
            : bDate < aDate
            ? -1
            : 0;
        });
        setProjects(toBeSorted);
        userService
          .getNotifications({ uid: user.id, name: user.name })
          .then((notifs) => {
            console.log(notifs.data.milestones);
            let tempM = [];
            let tempP = [];
            notifs.data.projects.forEach((n) => {
              if (n.status.toLowerCase() != "completed") tempP.push(n);
            });
            notifs.data.milestones.forEach((n) => {
              if (n.status.toLowerCase() != "complete") tempM.push(n);
            });

            setMNotifications(tempM);

            setPNotifications(tempP);
          });
      });
    }
    // setTimeout(() => {
    //   setLoading(false);
    // }, 700);
  }, []);

  return (
    <div className={`home-container`}>
      <Sidebar />

      {loading ? (
        <Loading />
      ) : (
        <div className="home-content-container">
          <h1 className="welcome-header header">
            Welcome Back {titleCase(user.name)}
          </h1>
          <FadeAnimation>
            <div className={` projects-container`}>
              <h1 className="upcoming-proj-header sub-header">
                Upcoming Projects
                <hr className="purp-line" />
              </h1>

              <div className="card-container">
                {projects.map((project, i) => {
                  if (i < 5) {
                    let pNameStripped = project.project_name.replace(
                      /^(.{11}[^\s]*).*/,
                      "$1"
                    );
                    pNameStripped != project.project_name
                      ? (pNameStripped += "...")
                      : (pNameStripped = pNameStripped);
                    return (
                      <div
                        onClick={() =>
                          navigate(`/projects/milestone/${project.id}`)
                        }
                        key={i}
                        className="project-card"
                      >
                        <p className="card-header">{pNameStripped}</p>
                        <p className="card-date">
                          {project.estimated_end_date}
                        </p>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </FadeAnimation>
          <FadeAnimation>
            {/* <Pi /> */}
            <div className="task-container">
              <h1 className="sub-header">
                Recent Activity
                <hr className="purp-line" />
              </h1>

              <div className="activity-container">
                <div className="scroll-y mx-2">
                  <h3>Recent Projects</h3>

                  <Table>
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>Date </th>
                        <th>Project Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pNotifications.map((item) => {
                        let formattedDate = new Date(item.createdAt);
                        let month = formattedDate.getMonth() + 1;
                        let day = formattedDate.getDate();
                        let year = formattedDate.getFullYear();
                        let totalString = `${month}-${day}-${year}`;
                        return (
                          <tr
                            onClick={() => navigate("/projects-view")}
                            className="pointer hover-purp"
                          >
                            <td>{totalString}</td>
                            <td>{item.project_name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
                <div className="scroll-y">
                  <h3>Recent Milestones</h3>
                  <Table>
                    <thead>
                      {" "}
                      <tr>
                        <th style={{ width: "20%" }}>Date </th>
                        <th>Milestone Name</th>
                        <th>Project Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mNotifications.map((item) => {
                        let formattedDate = new Date(item.createdAt);
                        let month = formattedDate.getMonth() + 1;
                        let day = formattedDate.getDate();
                        let year = formattedDate.getFullYear();
                        let totalString = `${month}-${day}-${year}`;
                        return (
                          <tr
                            onClick={() =>
                              navigate(`/projects/milestone/${item.project_id}`)
                            }
                            className="pointer hover-purp"
                          >
                            <td>{totalString}</td>
                            <td>{item.milestone_name}</td>
                            <td>
                              {projects.map((p) => {
                                return p.id == item.project_id
                                  ? p.project_name
                                  : null;
                              })}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </FadeAnimation>
        </div>
      )}
    </div>
  );
}
export default HomePage;
