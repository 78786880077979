import React from "react";
import FontAwesome from "react-fontawesome";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { fadeInDown, fadeInUp } from "react-animations";
const FadeUp = styled.div`
  animation: 0.5s ${keyframes`${fadeInUp}`};
`;
const FadeDown = styled.div`
  animation: 0.5s ${keyframes`${fadeInDown}`};
`;
function ProjectSelect() {
  const navigate = useNavigate();

  function handleRoute(path) {
    navigate(path);
  }

  return (
    <div className={`project-overview `}>
      <FadeDown>
        <div className="p-decision new-project-container">
          <div>
            <h1>Create a New Project</h1>
            <button
              onClick={() => handleRoute("/projects-create-new")}
              className="new-proj-btn"
            >
              create
            </button>
          </div>
          <FontAwesome
            className="nav-icon"
            name="plus"
            size="4x"
            style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      </FadeDown>
      <FadeUp>
        <div className="p-decision view-projects-container">
          <div>
            <h1>View Assigned Projects</h1>
            <button
              onClick={() => handleRoute("/projects-view")}
              className="view-proj-btn"
            >
              view
            </button>
          </div>
          <FontAwesome
            className="nav-icon"
            name="clipboard-list"
            size="9x"
            style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
          />
        </div>
      </FadeUp>
    </div>
  );
}

export default ProjectSelect;
