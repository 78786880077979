import React from "react";
import { Card, Button } from "react-bootstrap";
import villain from "../avatars/villain.png";
function User() {
  return (
    <div className="user-card">
      <Card style={{ width: "18rem" }}>
        <Card.Img style={{ background: "black" }} variant="top" src={villain} />
        <Card.Body>
          <Card.Title>Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
          <Button variant="primary">Go somewhere</Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default User;
